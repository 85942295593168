import { createContext, useContext } from 'react';

const CurrentUserContext = createContext<CurrentUserCookie | undefined>(
  undefined
);

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }
  return context;
};

export const CurrentUserProvider = CurrentUserContext.Provider;
